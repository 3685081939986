@import 'styles/layers.scss';

@layer component {
  .input {
    width: 105px;
    height: 31px;
    padding: 7px 11.7px;
    font-size: 12px;
  }
  
  .input-wrap {
    width: 105px;
  }
  
  .input-label {
    margin-bottom: 10px;
    opacity: 0.3;
  }
}