@import 'styles/layers.scss';

@layer component {
  .content {
    width: 420px;
    background-color: transparent;
    padding: 0 20px 20px 0;
  }
  
  .title {
    margin-bottom: 12px;
    font-size: 14px;
    font-weight: 300;
  }
}