$radio-size: 18px;
$radio-checked-size: 10px;
$radio-ripple-size: 15px;

@import 'styles/layers.scss';

@layer uikit {
  .container {
    display: block;
    cursor: pointer;
    font-size: 12px;
    &_disabled {
      cursor: not-allowed;
      opacity: 0.4;
    }
  }
  
  .input {
    position: absolute;
    opacity: 0;
    display: none;
    &:checked + .checkmark:after {
      transform: scale(1);
    }
  }
  
  .checkmark {
    display: inline-block;
    user-select: none;
    min-height: $radio-size;
    position: relative;
    padding: 0 $radio-size 0 0;
    margin-right: 6px;
    margin-bottom: 0;
    vertical-align: bottom;
    &:before, &:after {
      position: absolute;
      content: '';
      border-radius: 50%;
      transition: all .3s ease;
      transition-property: transform, border-color;
    }
    &:before {
      left: 0;
      top: 0;
      width: $radio-size;
      height: $radio-size;
    }
    &:after {
      top: calc($radio-size / 2 - $radio-checked-size / 2);
      left: calc($radio-size / 2 - $radio-checked-size / 2);
      width: $radio-checked-size;
      height: $radio-checked-size;
      transform: scale(0);
    }
  }
  
  .label {
    font-size: 12px;
    user-select: none;
  }
  
  .dark {
    .input {
      &:checked + .checkmark:before {
        border-color: var(--white2);
      }
    }
    .checkmark {
      &:before {
        border: 1px solid var(--white2);
      }
      &:after {
        background: var(--white);
      }
    }
    .label {
      color: var(--white);
    }
  }  

  .advanced {
    width: fit-content;
  }
}
