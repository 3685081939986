@import 'styles/layers.scss';

@layer component {
  .wrap {
    width: 277px;
    min-width: 277px; 
  }

  .reset-filters {
    font-size: 12px;
    color: var(--black32);
    cursor: pointer;
    margin-bottom: 20px;
  }

  @media screen and (max-width: 1120px) {
    .wrap {
      display: none;
    }
  }
}