@import 'styles/layers.scss';

@layer component {
  .dropdown-toggle {
    padding: 0;
    height: fit-content;
  }
  
  .content {
    margin-left: 38px;
  }
  
  .dropdown-menu {
    padding: 0;
    width: 182px;
    border-radius: 6px;
    right: 0;
  }
  
  .dropdown-menu-item {
    height: 44px;
    &:hover:first-child {
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    }
    &:hover:last-child{
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }
  
  .dialog {
    min-width: 457px;
    width: 457px;
  }
  
  .body-modal {
    padding: 22px;
  }
  
  .content-modal {
    background-color: var(--black1);
    backdrop-filter: blur(52.5px);
  }
  
  .btn {
    width: 26px;
    height: 26px;
  }
}