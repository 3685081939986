@import 'styles/layers.scss';

@layer component {
  .content {
    position: relative;
    row-gap: 10px;
  }

  .categories {
    row-gap: 20px;
  }

  .radio-label {
    font-size: 12px;
  }
}
