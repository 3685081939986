@import 'styles/layers.scss';

@layer component {
  .content {
    row-gap: 10px;
  }
  
  .input  {
    width: 105px;
    height: 31px;
    padding: 7px 11.7px;
  }
  
  .label, .radio-label {
    opacity: 0.3;
  }
  
  .group {
    column-gap: 20px;
    row-gap: 12px;
  }
  
  .extports {
    column-gap: 10px;
  }
  
  .radio-label {
    margin-bottom: 13px;
  }
  
}